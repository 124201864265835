import React from 'react';
import { Box, Container } from '@chakra-ui/react';
import Logo from 'components/Logo';
import SocialsNav from 'components/SocialsNav';

const Header: React.FC = () => (
  <Box as="header" mt="2" py="6" color="dark.900">
    <Container
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Logo />
      <SocialsNav iconSize="8" />
    </Container>
  </Box>
);

export default Header;
