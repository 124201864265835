import React from 'react';
import { HStack, Link, Icon, IconProps } from '@chakra-ui/react';
import DiscordIcon from 'components/DiscordIcon';
import OpenseaIcon from 'components/OpenseaIcon';
import TwitterIcon from 'components/TwitterIcon';

interface SocialsNavProps {
  iconSize?: IconProps['w'];
}

const SocialsNav: React.FC<SocialsNavProps> = ({ iconSize = '7' }) => (
  <HStack spacing="5">
    {[
      {
        href: '//opensea.io/collection/fancybearsmetaverse',
        icon: OpenseaIcon,
      },
      {
        href: '//discord.gg/fancybearsmetaverse',
        icon: DiscordIcon,
      },
      {
        href: '//twitter.com/FancyBearsMeta',
        icon: TwitterIcon,
      },
    ].map(({ href, icon }, i) => (
      <Link
        key={i}
        href={href}
        target="_blank"
        display="flex"
        alignItems="center"
      >
        <Icon as={icon} w={iconSize} h="auto" />
      </Link>
    ))}
  </HStack>
);

export default SocialsNav;
