import React from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import FanadiseLogo from 'components/FanadiseLogo';
import useTranslate from 'hooks/useTranslate';
import { learnMoreUrl, traitSwapAppUrl, vestingAppUrl } from 'config';
import SocialsNav from 'components/SocialsNav';

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <Box as="footer" py="8" color="dark.900">
      <Container>
        <HStack justify="space-between">
          <SocialsNav />

          <HStack justify="space-between" spacing="6">
            <Link href={learnMoreUrl} target="_blank">
              <Button as="span" variant="link" fontSize="sm" color="dark.900">
                {translate('footer:learnMore')}
              </Button>
            </Link>
            {traitSwapAppUrl && (
              <Link href={traitSwapAppUrl} target="_blank">
                <Button as="span" variant="link" fontSize="sm" color="dark.900">
                  {translate('footer:traitSwapApp')}
                </Button>
              </Link>
            )}
            {vestingAppUrl && (
              <Link href={vestingAppUrl} target="_blank">
                <Button as="span" variant="link" fontSize="sm" color="dark.900">
                  {translate('footer:vestingApp')}
                </Button>
              </Link>
            )}
          </HStack>
        </HStack>

        <Divider my="2.5" />

        <LinkBox as={Center}>
          <Text fontSize="sm" fontWeight="semibold">
            {translate('footer:powered')}
          </Text>
          <LinkOverlay href="//fanadise.com" target="_blank">
            <FanadiseLogo width="115" />
          </LinkOverlay>
        </LinkBox>
      </Container>
    </Box>
  );
};

export default Footer;
