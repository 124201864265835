import en from './locales/en.json';

export { default as theme } from './theme';

export const publicUrl = process.env.PUBLIC_URL;

export const locales = {
  en,
};

export const learnMoreUrl = process.env.REACT_APP_LEARN_MORE_URL;
export const vestingAppUrl = process.env.REACT_APP_VESTING_APP_URL!;
export const traitSwapAppUrl = process.env.REACT_APP_TRAIT_SWAP_APP_URL!;
export const stakingAppUrl = process.env.REACT_APP_STAKING_APP_URL!;
