import React from 'react';
import HomePage from 'pages/HomePage';
import LocaleProvider from 'providers/LocaleProvider';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { locales, theme } from './config';
import Layout from 'components/Layout';
import Background from 'components/Background';

const extendedTheme = extendTheme(theme);

const App: React.FC = () => (
  <ChakraProvider theme={extendedTheme}>
    <LocaleProvider translations={locales.en}>
      <Background />

      <Layout>
        <HomePage />
      </Layout>
    </LocaleProvider>
  </ChakraProvider>
);

export default App;
