import React from 'react';
import {
  AspectRatio,
  AspectRatioProps,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

interface CardProps extends Omit<AspectRatioProps, 'title'> {
  imgUrl: string;
  bgUrl: string;
  title: React.ReactNode;
  description: React.ReactNode;
  linkLabel: React.ReactNode;
  linkHref: string;
}

const Card: React.FC<CardProps> = ({
  imgUrl,
  bgUrl,
  title,
  description,
  linkLabel,
  linkHref,
  ...rest
}) => (
  <AspectRatio
    ratio={4 / 5}
    bgPosition="center"
    bgRepeat="no-repeat"
    bgImage={`url(${bgUrl})`}
    style={{ backgroundSize: 'cover' }}
    borderRadius="lg"
    boxShadow="0px 6px 24px 0px rgba(74, 70, 81, 0.23)"
    {...rest}
  >
    <Flex p="8" direction="column" align="center">
      <Image src={imgUrl} w="220px" />

      <VStack textAlign="center">
        <Heading fontSize="2xl" fontWeight="bold">
          {title}
        </Heading>

        <Text maxW="sm" fontSize="sm">
          {description}
        </Text>
      </VStack>

      {linkHref ? (
        <Button
          as={Link}
          href={linkHref}
          size="lg"
          colorScheme="dark"
          variant="solid"
          bgColor="dark.900"
          mt="6"
        >
          {linkLabel}
        </Button>
      ) : (
        <Button
          size="lg"
          bgColor="dark.900"
          variant="solid"
          colorScheme="dark"
          isDisabled
          mt="6"
        >
          {linkLabel}
        </Button>
      )}
    </Flex>
  </AspectRatio>
);

export default Card;
