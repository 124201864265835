import React from 'react';
import useTranslate from 'hooks/useTranslate';
import { Container, useBreakpointValue } from '@chakra-ui/react';
import Card from 'components/Card';
import {
  traitSwapAppUrl,
  publicUrl,
  vestingAppUrl,
  stakingAppUrl,
} from 'config';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

const HomePage: React.FC = () => {
  const translate = useTranslate();

  const slidesPerView = useBreakpointValue({
    base: 1.15,
    sm: 1.75,
    md: 2.5,
    lg: 3,
  });

  const cardRatio = useBreakpointValue({
    base: 3.1 / 5,
    sm: 2.5 / 5,
    md: 2.75 / 5,
    lg: 3 / 5,
  });

  return (
    <Container w="full">
      <Swiper
        spaceBetween={18}
        threshold={10}
        slidesPerView={slidesPerView}
        style={{ overflow: 'visible' }}
      >
        <SwiperSlide>
          <Card
            imgUrl={`${publicUrl}/traitswap.png`}
            bgUrl={`${publicUrl}/traitswap-bg.png`}
            title={translate('cards:traitsSwap:title')}
            description={translate('cards:traitsSwap:description')}
            linkHref={traitSwapAppUrl}
            linkLabel={translate('cards:traitsSwap:linkLabel')}
            color="white"
            bgColor="primary.500"
            ratio={cardRatio}
          />
        </SwiperSlide>

        <SwiperSlide>
          <Card
            imgUrl={`${publicUrl}/staking.png`}
            bgUrl={`${publicUrl}/staking-bg.png`}
            title={translate('cards:staking:title')}
            description={translate('cards:staking:description')}
            linkLabel={translate('cards:staking:linkLabel')}
            linkHref={stakingAppUrl}
            color="white"
            bgColor="secondary.500"
            ratio={cardRatio}
          />
        </SwiperSlide>

        <SwiperSlide>
          <Card
            imgUrl={`${publicUrl}/vesting.png`}
            bgUrl={`${publicUrl}/vesting-bg.png`}
            title={translate('cards:vesting:title')}
            description={translate('cards:vesting:description')}
            linkLabel={translate('cards:vesting:linkLabel')}
            linkHref={vestingAppUrl}
            color="dark.900"
            bgColor="secondary.500"
            ratio={cardRatio}
          />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default HomePage;
