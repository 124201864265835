import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import Footer from 'components/Footer';
import Header from 'components/Header';

const Layout: React.FC = ({ children }) => (
  <Box minH="full" display="flex" flexDir="column">
    <Header />
    <Center p="4" w="full" overflow="hidden" flex="1">
      {children}
    </Center>
    <Footer />
  </Box>
);

export default Layout;
